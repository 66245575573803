<script>
import richMarker from 'googlemaps-rich-marker-web';
import upperFirst from 'lodash/upperFirst';

const getMarkerHtml = (image, isActive, orientation) => {
  const baseClass = `marker marker--${orientation}`;
  const markerClass = isActive ? `${baseClass} marker--active` : baseClass;

  return `
  <div class="${markerClass}">
    <div class="marker__wrap">
      <img class="marker__image" src="${image}">
    </div>
  </div>
  `;
};

export default {
  name: 'GoogleMarker',
  inject: ['getMap'],
  props: {
    lat: {
      required: true,
      type: Number,
    },
    lng: {
      required: true,
      type: Number,
    },
    title: {
      required: false,
      type: String,
      default: '',
    },
    id: {
      required: true,
      type: Number,
    },
    slug: {
      required: true,
      type: String,
    },
    image: {
      required: true,
      type: String,
    },
    isActive: {
      required: false,
      default: false,
      type: Boolean,
    },
    orientation: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    marker: null,
  }),
  watch: {
    lat() {
      this.reInit();
    },
    lng() {
      this.reInit();
    },
    isActive() {
      this.marker.setContent(this.getMarkerHtml());
    },
  },
  mounted() {
    this.$loadGoogleMaps(this.addMarker);
  },
  updated() {
    // console.log('updated');
  },
  destroyed() {
    if (this.marker === null) {
      return;
    }

    this.marker.setMap(null);
    this.$emit('markerDestroyed', this.id);
  },
  methods: {
    async addMarker() {
      const map = this.getMap();
      this.marker = new richMarker.RichMarker({
        position: new this.$google.maps.LatLng(this.lat, this.lng),
        title: this.title,
        optimized: false,
        shadow: false,
        content: this.getMarkerHtml(),
        zIndex: 1,
      });

      // Standard Google Maps events
      const events = [
        'click',
        // Disabled because of possible performance impact
        // 'rightclick',
        // 'dblclick',
        // 'drag',
        // 'dragstart',
        // 'dragend',
        // 'mouseup',
        // 'mousedown',
        'mouseover',
        'mouseout',
      ];

      events.forEach(eventName => {
        this.marker.addListener(eventName, event => {
          this.$emit(`marker${upperFirst(eventName)}`, {
            event,
            map,
            marker: this.marker,
            id: this.id,
            slug: this.slug,
          });
        });
      });

      this.marker.setMap(map);

      this.$emit('markerAdded', {
        id: this.id,
        marker: this.marker,
      });
    },
    getMarkerHtml() {
      return getMarkerHtml(this.image, this.isActive, this.orientation);
    },
    async reInit() {
      this.marker.setMap(null);
      this.addMarker();
    },
  },
  // A dummy render function. The marker component is not supposed to render any HTML.
  render() {
    return '';
  },
};
</script>
